
    import Vue from "vue";
    import Component from "vue-class-component";
    import ApiButton from "@/components/ApiButton.vue";
    import apiClient from "@/utilities/ApiClient";
    import { Route } from "vue-router";
    import { Watch } from "vue-property-decorator";
    import { required } from "vuelidate/lib/validators";
    import { Validations } from "vuelidate-property-decorators";
    import SlideUpDown from "vue-slide-up-down";
    import * as toastr from "toastr";
    import { UserStatus } from '@/model/Enums';

    interface IResetPassword {
        key: string;
        state: StateType;
        userStatus: UserStatus;
        email: string;
        password: string;
        passwordRepeat: string;
        message: string;
    }

    enum StateType {
        None = 0,
        Ready = 1,
        Expired = 2,
        Success = 3,
        ValidationFailed = 4,
        Failed = 5
    }

    @Component({
        components: { SlideUpDown, ApiButton }
    })
    export default class ResetPassword extends Vue {

        //
        // -- lifecycle hooks, etc.
        //

        mounted() {
            this.onKeyChanged(this.$router.currentRoute.params.key);
            console.log("@@@ mounted - key = " + this.resetPassword.key);
        }

        // if the group ID changes in the URL, the component *will not* reload - so we need to handle it here
        @Watch("$route", { immediate: true, deep: true })
        onRouteChanged(route: Route) {
            this.onKeyChanged(route.params.key);
            console.log("@@@ Route changed - key = " + this.resetPassword.key);
        }

        //
        // -- properties
        //

        resetPassword: IResetPassword = {
            key: "",
            state: StateType.None,
            userStatus: UserStatus.None,
            email: "",
            password: "",
            passwordRepeat: "",
            message: ""
        };

        get isMessage(): boolean {
            return !!this.resetPassword.message;
        }

        get messageVariant(): string {
            if (this.resetPassword.state === StateType.Expired) return "warning";
            if (this.resetPassword.state === StateType.Failed) return "danger";
            if (this.resetPassword.state === StateType.ValidationFailed) return "danger";
            return "primary";
        }

        get shouldShowForm(): boolean {
            return this.resetPassword.state === StateType.None ||
                this.resetPassword.state === StateType.Ready ||
                this.resetPassword.state === StateType.Failed ||
                this.resetPassword.state === StateType.ValidationFailed;
        }

        get isNew(): boolean {
            return this.resetPassword.userStatus !== UserStatus.Active;
        }

        //
        // -- methods
        //

        private async onKeyChanged(key: string) {
            if (this.resetPassword.key === key) return;
            this.resetPassword.key = key;           
            const response: IResetPassword = await apiClient.post("/api/authentication/resetPasswordInit", this.resetPassword);
            this.resetPassword = response;
            this.$v.$reset();
            this.resetPassword.passwordRepeat = "";
        }

        async submit() {
            this.$v.$touch();
            if (this.$v.resetPassword.$invalid) {
                toastr.info("Please fix the highlighted errors.");
                return;
            }
            const response: IResetPassword = await apiClient.post("/api/authentication/resetPassword", this.resetPassword);
            this.resetPassword = response;
        }

        @Validations()
        validations() {
            return {
                resetPassword: {
                    password: { required },
                    passwordRepeat: {
                        passwordsMatch: (passwordRepeat: string, model: IResetPassword) => passwordRepeat === model.password
                    }
                }
            };
        }
    }
